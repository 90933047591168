@font-face {
  font-family: 'HYWenHei-85W';
  src: url('./HYWenHei-HEW.eot');
  src: url('./HYWenHei-HEW.eot?#iefix') format('embedded-opentype'),
      url('./HYWenHei-HEW.woff2') format('woff2'),
      url('./HYWenHei-HEW.woff') format('woff'),
      url('./HYWenHei-HEW.ttf') format('truetype'),
      url('./HYWenHei-HEW.svg#HYWenHei-HEW') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-container {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/genshin-todo-f2b7b.appspot.com/o/backgrounds%2F8f0dea7e-b29a-4bbe-985b-65c156e0b785.jpeg?alt=media&token=6154e054-8b5f-4f46-bc2e-1b22a4f61e47");
  background-size: cover;
  background-position: center top; 
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'HYWenHei-85W';
}

.commission-book-background {
  z-index: 1;

  width: 80%;
}

.book-container {
  width: 100vw;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
}

.region-dropdown {
  z-index: 2;
  position: absolute;

  border-color: rgba(0, 0, 0, 0.135);
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;

  vertical-align: middle;
  text-align: left;
  display: flex;
  align-items: center;

  padding-left: 1%;

  height: 6%;
  width: 17%;

  top: 8%;
  left: 62%;

  transition: 0.3s;
}

.region-dropdown:hover {
  scale: 1.05;
  transition: 0.3s;
}

.dropdown-arrow {
  left: 87%;
  top: 30%;
  width: 1vw;
  position: absolute;
}

.region-text {
  color: rgb(125, 98, 70);
  font-size: 1.3vw;
  position: absolute;
  top: 10%;
}

.dropdown-text {
  color: white;
  font-size: 1.3vw;
}

.region-dropdown-menu {
  z-index: 2;
  position: absolute;
  background-color: black;
  border-radius: 8px;
  padding: 1%;

  width: 17%;

  top: 14%;
  left: 62%;
}

.dropdown-option {
  margin-bottom: 3%;
}

.dropdown-option:hover {
  background-color: rgb(125, 98, 70);
  border-radius: 10px;
}

.commision-list-container {
  z-index: 2;
  position: absolute;

  top: 15%;
  left: 38.5%;

  width: 44%;
  height: 53%;

  padding-left: 1%;

  overflow-y: auto;

}

.action-bar-container {
  z-index: 2;
  position: absolute;

  top: 75%;
  left: 38.5%;

  width: 43.5%;
  height: 15%;

  /* border-style: solid;
  border-width: 2px;;
  border-color: rgba(0, 0, 0, 0.135); */

  margin-bottom: 1%;

  align-items: center;

  background-color: rgba(255, 255, 255, 0.289);
  transition: 0.2s;

  padding-left: 1%;
}

.sign-in-text {
  font-size: 1.3vw;
}

.signed-in-name {
  font-size: 1.1vw;
}

.sign-in-box {
  position: absolute;
  line-height: 1.2vw;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 95%;
  padding: 0!important;
  padding-left: 1%!important;

  background-color: rgba(255, 255, 255, 0.843);
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.135);
}

.sign-in-box:hover {
  background-color: rgba(255, 255, 255, 1);
  scale: 1.03;
  transition: 0.2s;
}

.default-avatar {
  top: 0;
  width: 75%!important;
}

.add-commission-text {
  font-size: 1.3vw;
}

.add-commission-box {
  z-index: 2;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  margin-left: 2%;
  
  position: absolute;
  left: 34.3%;
  height: 95%;

  background-color: rgba(255, 255, 255, 0.843);
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.135);
}

.add-commission-box:hover {
  background-color: rgba(255, 255, 255, 1);
  scale: 1.03;
  transition: 0.2s;
}

.music-box {
  z-index: 2;
  align-items: center;
  display: flex;
  padding-left: 1%!important;

  width: 40%!important;
  
  position: absolute;
  left: 62%;
  height: 95%;

  background-color: rgba(255, 255, 255, 0.843);
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.135);
}

.current-song-cover {
  width: 33%;
}

.music-title {
  font-size: 1.3vw;
  /* top: 0; */
}

.music-marquee {
  position: absolute;
  width: 62%;
  left: 35%;
  top: 7%;
}

.play-control {
  width: 50%;
  text-align: center;

  background-color: rgba(255, 255, 255, 0.843);
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.135);

  margin-top: 10%;
  margin-left: 3%;
  margin-right: 2%;

  font-size: 1.3vw;

  transition: 0.2s;
}

.play-control:hover {
  background-color: white;
  scale: 1.05;
  transition: 0.2s;
}

.song-change {
  width: 50%;
  text-align: center;
  
  background-color: rgba(255, 255, 255, 0.843);
  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.135);

  margin-top: 10%;

  font-size: 1.3vw;
}

.song-change:hover {
  background-color: white;
  scale: 1.05;
  transition: 0.2s;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.805);
  position: absolute;
  z-index: 4;

  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.sign-out-modal {
  z-index: 5;
  position: absolute;
  display: flex;

  border-style: solid;
  border-color: rgb(85, 73, 40);
  border-width: 2px;

  background-color: rgb(255, 248, 233);

  height: 15vh;
  width: 40vw;

  align-items: center;
  text-align: center;

  padding-top: 1%;
}

.sign-out-header {
  text-align: center!important;
  justify-content: center;
  font-size: 2vw;
}

.modal-button-row {
  margin-top: 5%!important;
}

.confirm-button {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(85, 73, 40);
  margin-left: 2%;
  margin-bottom: 1%;

  transition: 0.2s;
}

.confirm-button:hover {
  transition: 0.2s;
  background-color: rgb(118, 255, 125);
  scale: 1.05;
}

.cancel-button {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(85, 73, 40);
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 1%;
}

.cancel-button:hover {
  transition: 0.2s;
  background-color: rgb(255, 81, 81);
  color: white;
  scale: 1.05;
}

.book-subheader {
  z-index: 2;
  position: absolute;

  top: 12%;
  left: 20.5%;

  width: 15vw;

  justify-content: center;
  text-align: center;

  color: rgb(169, 150, 129);
  font-size: 1.1vw;
}

.ayaka {
  color: rgb(164, 152, 57);
}

.commission-fraction {
  z-index: 2;
  position: absolute;

  top: 27%;
  left: 20.5%;

  width: 15vw;

  justify-content: center;
  text-align: center;

  color: rgb(85, 73, 40);
  font-size: 2.2vw;
}

.commission-progress-bar {
  z-index: 3;
  position: absolute;

  top: 39%;
  left: 21.9%;

  width: 12vw;

  display: grid;
  overflow-x: auto;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(23%,1fr));
  /* grid-auto-rows: 100%; */
  grid-auto-columns: minmax(23%,1fr);
  gap: 2.9%;
}

.commission-progress-bar::-webkit-scrollbar {
  display: none;
}

.commission-progress-icon {
  width: 100%!important;
}

.progress-0 {
  opacity: 0.3;
}

.bonus-rewards-conainer {
  z-index: 3;
  position: absolute;

  top: 52%;
  left: 18.5%;

  width: 18vw;

  display: grid;
  overflow-x: auto;
  grid-template-columns: repeat(auto-fill, minmax(30%,1fr));
  /* grid-auto-rows: 100%; */
  grid-auto-columns: minmax(40%,1fr);
  gap: 1%;
}

.bonus-rewards-conainer::-webkit-scrollbar {
  display: none;
}

.bonus-reward-squares {
  width: 100%;
  transition: 0.2s;
}

.bonus-reward-squares:hover {
  scale: 1.1;
  transition: 0.2s;
}

.add-commission-modal {
  position: absolute;
  z-index: 5;
  display: flex;
  padding: 1%;



  width: 50vw;
  height: 32vw;

  border-style: solid;
  border-color: rgb(85, 73, 40);
  border-width: 2px;
  border-radius: 5px;

  background-color: rgb(255, 248, 233);
}

.katheryne-pic {
  width: 35%!important;
}

.add-commission-header {
  font-size: 1.5vw;
  margin-left: 2%;
}

.new-commission-title-field {
  width: 90%;
}

.new-commission-notes {
  margin-left: 2%;
}

.commission-modal-button {
  text-align: center;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(85, 73, 40);
  border-radius: 15px;
  font-size: 1.2vw;

  color: rgb(255, 248, 233);
  background-color: rgb(85, 73, 40);
}

.commission-modal-button:hover {
  scale: 1.05;
  transition: 0.2s;
}

.commission-modal-create-button {
  margin-right: 1%;
}

.commission-modal-create-button:hover {
  background-color: rgb(103, 255, 86);
  border-color: rgb(103, 255, 86);
  color: rgb(85, 73, 40);
}

.commission-modal-cancel-button {
  margin-left: 1%;
}

.commission-modal-cancel-button:hover {
  background-color: rgb(255, 100, 86);
  border-color: rgb(255, 100, 86);
}

.modal-error-message {
  font-size: 1.1vw;
  color: rgb(255, 64, 64);
  font-weight: bolder;
}

.commission-details-modal {
  position: absolute;
  z-index: 5;
}

.commission--details-base-image {
  max-height: 80vh;
  max-width: 90vw;
}

.blue-overlay {
  background-color: rgba(0, 0, 0, 0.862);
  position: absolute;
  z-index: 4;

  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.commission-details-image {
  position: absolute;
  z-index: 5;
  top: 2.5%;
  left: 2.5%;
  width: 18%;

  border-radius: 5px;
}

.commission-details-title {
  position: absolute;
  z-index: 5;

  top: 4%;
  left: 23%;
  width: 60%!important;

  color: rgb(238, 214, 170);
  font-size: 1.2vw;
}

.long-title {
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.main-details-content {
  position: absolute;
  z-index: 5;

  top: 25%;
  left: 5%;
  width: 90%!important;

  color: rgb(145, 129, 109);
  font-size: 1.5vw;
}

.main-details-content-header {
  color: rgb(147, 84, 64);
}

.commission-details-buttons {
  z-index: 5;
  position: absolute;

  top: 91%;
  left: 0%;

  font-size: min(2vh, 2vw);

  width: 100%;
  height: 6%;

  text-align: center;
}

.commission-details-button {
  border-radius: 25px;
  color: white;
  background-color: rgb(75, 82, 100);
  width: 80%!important;

  display: flex;

  align-items: center;
  vertical-align: middle;
  justify-content: center;

  transition: 0.2s;
}

.commission-details-button:hover {
  scale: 1.05;
  transition: 0.2s;
}

.abandon-button:hover {
  color: rgb(255, 49, 49);
  transition: 0.2s;
}

.complete-button:hover {
  color: rgb(93, 255, 142);
  transition: 0.2s;
}

.commision-details-error {
  z-index: 5;
  position: absolute;
  color: red;

  font-size: 1.2vw;

  top: 82%;
  left: 5%;

  font-weight: bolder;
}

.song-change-modal {
  position: absolute;
  z-index: 4;
  display: grid;
  padding: 1%;

  overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(30%,1fr));
  grid-auto-rows: 30%;
  grid-auto-columns: minmax(30%,1fr);
  gap: 1%;

  width: 50vw;
  height: 32vw;

  border-style: solid;
  border-color: rgb(85, 73, 40);
  border-width: 2px;
  border-radius: 10px;

  text-align: center;

  background-color: rgb(255, 248, 233);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.song-block {
  z-index: 5;

  border-style: solid;
  border-color: rgb(85, 73, 40);
  border-width: 2px;
  border-radius: 5px;

  background-color: white;

  display: flex;

  text-align: left;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.song-block:hover {
  scale: 1.05;
  transition: 0.2s;
  color: white;
  background-color: #282c34;
  border-color: white;
}

.song-cover-picture {
  width: 110%;
}

.song-name {
  margin-left: 2%!important;
  width: 100%;
  font-size: 1vw;
}

.song-modal-title {
  font-size: 2vw;
}

.please-sign-in-modal {
  position: absolute;
  z-index: 4;
  display: flex;
  padding: 1%;

  width: 50vw;
  height: 32vw;

  border-style: solid;
  border-color: rgb(85, 73, 40);
  border-width: 2px;
  border-radius: 10px;

  text-align: center;
  align-items: center;

  background-color: rgb(255, 248, 233);
}


.plead-message-icon {
  width: 50%;
}

.plead-message {
  font-size: 1.5vw;
}

.small-plead {
  font-size: 1vw;
}

.credit-tag {
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 0;
  color: white;
  font-size: 1.5vw;
  font-weight: bolder;
}

.twitter-link {
  color: white;
  text-decoration: none;
  transition: 0.2s;
}

.twitter-link:hover {
  color: rgb(43, 131, 255);
  transition: 0.2s;
}

.all-complete-filler {
  z-index: 3;
  position: absolute;
  width: 15%;
  top: 85%;
  left: 20%;
}
