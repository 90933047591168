@font-face {
    font-family: 'HYWenHei-85W';
    src: url('./HYWenHei-HEW.eot');
    src: url('./HYWenHei-HEW.eot?#iefix') format('embedded-opentype'),
        url('./HYWenHei-HEW.woff2') format('woff2'),
        url('./HYWenHei-HEW.woff') format('woff'),
        url('./HYWenHei-HEW.ttf') format('truetype'),
        url('./HYWenHei-HEW.svg#HYWenHei-HEW') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.commission-container {
    width: 100%;

    height: 23%;

    border-style: solid;
    border-width: 2px;;
    border-color: rgba(0, 0, 0, 0.135);

    margin-bottom: 1%;

    align-items: center;

    background-color: rgba(255, 255, 255, 0.704);
    transition: 0.2s;
}

.commission-container:hover {
    scale: 1.02;
    background-color: white;
    transition: 0.2s;
}

.commission-icon {
    width: 100%;
}

.commission-text {
    font-family: 'HYWenHei-85W';
    color: rgb(78, 61, 43);
    font-size: 1.3vw;
}

.reward-columns {
    display: grid;
    overflow-x: auto;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(23%,1fr));
    /* grid-auto-rows: 100%; */
    grid-auto-columns: minmax(23%,1fr);
    gap: 2%;
}

.commision-title-wrapper::-webkit-scrollbar {
    display: none;
}

.reward-columns::-webkit-scrollbar {
    display: none;
  }

.reward-icon {
    width: 100%;
    margin-right: 2%;
    transition: 0.2s;
}

.reward-icon:hover {
    scale: 1.05;
    transition: 0.2s;
}

.check-column {
    width: 100%;
}